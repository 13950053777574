import { firebase } from './db';
import { BLOG_ROUTE, FEATURED_ROUTE } from './strings';
// import { type } from 'os';
var slugify = require('slugify');
const uuidv5 = require('uuid/v5');
const APP_UUID_NAMESPACE = '1b671a64-40d5-491e-99b0-da01ff1f3341';/// this is randomly chosen but same on app and mobile
// const he = require('he');

// export const serverApi = 'http://localhost:3000/api/';
// export const serverApi = 'http://localhost:5000/podcastapp-767c2/us-central1/';  // for dev
export const serverApi = 'https://us-central1-podcastapp-767c2.cloudfunctions.net/';
export const cdnApi = 'https://podcastapp-767c2.firebaseapp.com/';

export const storageUrl = 'https://firebasestorage.googleapis.com/v0/b/podcastapp-767c2.appspot.com/';

export const reviewsLimit = 8;
export const firestoreApi = 'https://firestore.googleapis.com/v1/projects/books-af362/databases/(default)/documents/';

export const websiteUrl = 'https://tedapp.ai';
export const domainName = 'https://tedapp.ai/';
export const domainNameWeb = 'https://tedapp.ai/explore/';

export const appStoreUrl = 'https://apps.apple.com/app/apple-store/id6477714480?pt=120962850&ct=WebBlog&mt=8';
export const appStoreUrlLanding = 'https://apps.apple.com/app/apple-store/id6477714480?pt=120962850&ct=WebLanding&mt=8';
// export const playStoreUrl = 'https://play.google.com/apps/testing/com.podurama.podcast';

export const webHomeUrl = domainName + 'home';
export const supportEmail = 'support@tedapp.ai';
export const testflightUrl = '';
export const appleStoreUrl = appStoreUrl; // 'https://apps.apple.com/us/app/podurama/id6477714480';
export const playStoreUrl = 'https://play.google.com/store/apps/details?id=com.podurama.books';
export const macStoreUrl = 'https://apps.apple.com/us/app/podcast-player-podurama/id6477714480?platform=mac';
export const windowsStoreUrl = '';

export const imagesCDNUrl = 'https://books-images.b-cdn.net/';

export const staticAssetsPrefix = process.env.NODE_ENV === 'production' ? '/explore' : '';
export const domainPrefix = process.env.NODE_ENV === 'production' ? '/explore' : '';

export const topBarPremiumHeight = 0; // '32px';

export const articles = {
    'how-to-promote-podcast': {
        'title': 'How to Promote a Podcast: 21 Podcast Marketing Strategies',
        'imageSmall': 'promotion_small.png',
        'image': 'promotion-min.png',
        metaDescription: "Learn how to promote your podcast and reach a larger audience with effective podcast marketing tactics. From leveraging social media, blogs, and reviews to optimizing search results - we'll go over it all in this informative blog post!"
    },
    'what-is-podcast': {
        'title': 'What is a podcast and how it works? A simple explanation',
        'imageSmall': 'whatIsPodcast_small.jpg',
        'image': 'whatIsPodcast.jpg',
        metaDescription: 'Learn what podcasts are, how they work, and get recommendations for some of the best podcast shows that can help get you started. Find out all about how podcasts can add entertainment value into your life!'
    },
    'how-to-listen-to-podcasts': {
        'title': 'How to listen to podcasts for Free on any device',
        'imageSmall': 'howToListen_small.jpg',
        'image': 'howToListen.jpg',
        metaDescription: 'Discover how you can listen to podcasts anywhere with these free tips and tricks. Learn all the secrets of finding, downloading, and streaming your favorite podcasts onto any device.'
    },
};

export function convertToSlug(title) {
    if (title) {
        return slugify(title, {
            lower: true, remove: /[^\w\s-]/g
        });
    } else {
        return encodeURIComponent(title);
    }
}

export function compareIndex(a, b) {
    const indexA = parseInt(a.index || 0);
    const indexB = parseInt(b.index || 0);

    let comparison = 0;
    if (indexA > indexB) {
        comparison = 1;
    } else if (indexA < indexB) {
        comparison = -1;
    }
    return comparison;
}

export function compareScore(a, b) {
    const scoreA = a.score || 0;
    const scoreB = b.score || 0;

    let comparison = 0;
    if (scoreA > scoreB) {
        comparison = -1;
    } else if (scoreA < scoreB) {
        comparison = 1;
    }
    return comparison;
}


export function isLocalStoragePresent() {
    try {
        const key = "__some_random_key_27";
        window.localStorage.setItem(key, key);
        window.localStorage.removeItem(key);
        return true;
    } catch (e) {
        return false;
    }
}

const localMap = {};
export function saveLocally(key, val, expiresInHours = 24 * 3, isTemp = false) {
    if (isLocalStoragePresent() && !isTemp) {
        let now = Date.now();
        let scheduleInMillis = now + (expiresInHours * 60 * 60 * 1000);
        window.localStorage.setItem(key, val);
        window.localStorage.setItem(key + '_expiresIn', scheduleInMillis);
    } else {
        localMap[key] = val;
    }
}
export function retrieveLocally(key, isTemp = false) {
    if (isLocalStoragePresent() && !isTemp) {
        let now = Date.now();
        let expiresInMillis = localStorage.getItem(key + '_expiresIn');
        if (expiresInMillis < now) {
            removeStorage(key);
            return null;
        }
        return window.localStorage.getItem(key);
    } else {
        return localMap[key];
    }
}
function removeStorage(key) {
    try {
        localStorage.removeItem(key);
        localStorage.removeItem(key + '_expiresIn');
    } catch (e) {
        // console.log('removeStorage: Error removing key ['+ key + '] from localStorage: ' + JSON.stringify(e) );
        return false;
    }
    return true;
}

let currentPodcastData = {};
export function setCurrentPodcastData(podcastData) {
    currentPodcastData[podcastData.collectionId] = podcastData;
    return;
}
export function getCurrentPodcastData(collectionId) {
    return currentPodcastData[collectionId];
}

export function featuredAllUrl() {
    return firestoreApi + 'featured';
}
export function featuredMainUrl(slug) {
    return firestoreApi + 'featured/' + slug + '/final/article';
}
export function blogMainUrl(slug) {
    return firestoreApi + 'blog/' + slug + '/final/article';
}

export function addLinkPrefix(url) {
    return url ? (url.startsWith('http') ? url : 'https://' + url) : '';
}

export function isFeatured(route) {
    return route.startsWith(FEATURED_ROUTE);
}

export function isBlog(route) {
    return route.startsWith(BLOG_ROUTE);
}

export function isArticle(route) {
    return isFeatured(route) || isBlog(route) || route.includes('promote') || route.includes('how-to-listen') || route.includes('what-is');
}

export function isOnboard(route) {
    return route.includes('onboard') || route.includes('goals');
}

export function podcastSlugUrl(collectionName, itunesRawId) {
    return `/book/${convertToSlug(cleanText(collectionName))}-i${itunesRawId}`;
}
export function podcastHrefUrl(itunesRawId) {
    return `/book?PzID=${itunesRawId}`;
}

export const blogAuthors = {
    '1': { 'name': 'Amelia Thompson' },
    '2': { 'name': 'Ash Hall' },
    '3': { 'name': 'Jessica Taylor' },
};

export function secToDateDay(seconds) {
    return new Intl.DateTimeFormat('en-GB', {
        year: 'numeric',
        month: 'short',
        day: '2-digit'
        // second: '2-digit', // just for now testing
        // }).format(new Date(podcastEpisode.pubDate))}</p>
    }).format(Date.parse(new Date(seconds * 1000).toISOString().replace(" ", "T")))
    //  new Date(seconds * 1000).toISOString();
}

export function singular(text, val) {
    if (val === 1) {
        return text;
    } else {
        return text + 's';
    }
}

export function getPodcastImg(episode) {
    if (episode && episode.artworkUrl600) {
        return episode.artworkUrl600;
    } else if (episode && episode.thumbnail) {
        return episode.thumbnail;
    } else {
        return ''; // return some default image in future.
    }
}

export function showSubscriberCount(subscribers) {
    if (!isNaN(subscribers)) {
        if (subscribers < 1000) {
            if (subscribers < 1) {
                subscribers = subscribers + 1;
            }
            // return subscribers.toString();
            return (Math.ceil(subscribers / 100) / 10).toFixed(1) + 'K';
            // return subscribers;
        } else {
            return (subscribers / 1000).toFixed(1) + 'K';
        }
    } else {
        return '0.1K';
    }
}

export function getUUID(inputString) {
    if (!inputString) {
        return;
    }
    return uuidv5(inputString, APP_UUID_NAMESPACE);
}

export function getPodcastImgSize(imgUrl, size = 'lg') {
    let sizeVal;
    switch (size) {
        case 'xxs':
            sizeVal = 20;
            break;
        case 'xs':
            sizeVal = 80;
            break;
        case 'sm':
            sizeVal = 100;
            break;
        case 'md':
            sizeVal = 160;
            break;
        case 'lg':
            sizeVal = 300;
            break;
        default:
            sizeVal = 600;
    }

    const testStr = '/600x600';
    if (imgUrl && imgUrl.includes(testStr)) {
        const str = imgUrl.replace(testStr, `/${sizeVal}x${sizeVal}`);
        return str;
    } else {
        return imgUrl;
    }

}

export function getIdFromUID(userUID) {
    return userUID.slice(-9);
}

export function ratingValue(rating, fixed = 1, type = 'str') {
    if (rating && rating > 0 && type === 'num') {
        return parseFloat(rating.toFixed(fixed));
    } else if (rating && rating > 0 && type === 'str') {
        return rating.toFixed(fixed);
    }
    if (type === 'num') {
        return 0;
    } else {
        return '-';
    }
}

export const makeCancelable = (promise) => {
    let hasCanceled_ = false;

    const wrappedPromise = new Promise((resolve, reject) => {
        promise.then((val) => {
            console.log(val);
            hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)
        }
        );
        promise.catch((error) => {
            console.log(error);
            hasCanceled_ ? reject({ isCanceled: true }) : reject(error)
        }
        );
    });

    return {
        promise: wrappedPromise,
        cancel() {
            hasCanceled_ = true;
        },
    };
};

export function isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
}

export function mergeDeep(target, source) {
    let output = Object.assign({}, target);
    if (isObject(target) && isObject(source)) {
        Object.keys(source).forEach(key => {
            if (isObject(source[key])) {
                if (!(key in target))
                    Object.assign(output, { [key]: source[key] });
                else
                    output[key] = mergeDeep(target[key], source[key]);
            } else {
                Object.assign(output, { [key]: source[key] });
            }
        });
    }
    return output;
}

// export function objToArr(obj) {
//     Object.keys(obj).map(function (key) {
//         return [Number(key), obj[key]];
//     });
// }


export function secToTime(seconds, fullText = false, shortText = false) {
    if (typeof seconds === 'undefined' || seconds === 0) {
        return '--:--';
    }
    let secondsNumber = 0;
    if (typeof seconds === 'string' && seconds.includes(':')) {
        secondsNumber = 0;
        let secondsMicroSec = seconds.split('.')[0];
        let secondsList = secondsMicroSec.split(':');
        for (let i = 0; i < secondsList.length; i++) {
            // print('secondsList : ' + secondsList[i].toString());
            secondsNumber += (parseInt(secondsList[i]) || 0) *
                Math.pow(60, secondsList.length - i - 1);
            // print('secondsListNumber : ' + secondsNumber.toString());
        }

        // if (seconds.length > 4 && seconds.startsWith('00:')) {
        //     return seconds.substring(3);
        // }
        // return seconds;
    } else if (typeof seconds === 'string') {
        secondsNumber = parseInt(seconds);
    } else {
        secondsNumber = seconds;
    }
    const hrs = Math.floor(secondsNumber / 3600);
    const min = Math.floor((secondsNumber % 3600) / 60);
    const sec = Math.round(secondsNumber % 60);
    if (fullText) {
        if (shortText) {
            return (hrs > 0 ? (hrs + 'h ') : '') + doubleDigits(min) + 'm';
        } else {
            return (hrs > 0 ? (hrs + ' HR ') : '') + doubleDigits(min) + ' MINS';
        }
    } else {
        return (hrs > 0 ? (hrs + ':') : '') + doubleDigits(min) + ':' + doubleDigits(sec);
    }
}

export function secToDate(seconds) {
    return new Intl.DateTimeFormat('en-GB', {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit', // just for now testing
        // }).format(new Date(podcastEpisode.pubDate))}</p>
    }).format(Date.parse(new Date(seconds * 1000).toISOString().replace(" ", "T")))
    //  new Date(seconds * 1000).toISOString();
}

export function bytesToMB(bytes) {
    if (bytes) {
        return Math.round(bytes / Math.pow(10, 6)) + ' MB';
    }
}

const PSYCH = 'Psychology';
const SELF_GROWTH = 'Self growth';
const MINDFULNESS_HAPPINESS = 'Mindfulness & Happiness';
const BUSINESS_CAREER = 'Business & Career';
const MANAGEMENT_LEADERSHIP = 'Management & Leadership';
const ENTREPRENEURSHIP = 'Entrepreneurship';
const MARKETING_SALES = 'Marketing & Sales';
const PRODUCTIVITY = 'Productivity';
const MONEY_INVESTMENTS = 'Money & Investments';
const SEX_RELATIONSHIPS = 'Sex & Relationships';
const HEALTH_NUTRITION = 'Health & Nutrition';
const SPORTS_FITNESS = 'Sports & Fitness';
const SOCIETY_CULTURE = 'Society & Culture';
const BIOGRAPHY_MEMOIR = 'Biography & Memoir';
const RELIGION_SPIRITUALITY = 'Religion & Spirituality';
const FAMILY_KIDS = 'Family & Kids';
const HOME_ENVIRONMENT = 'Home & Environment';
const MOTIVATION_INSPIRATION = 'Motivation & Inspiration';
const TECHNOLOGY_FUTURE = 'Technology & Future';
const CREATIVITY = 'Creativity';
const POLITICS = 'Politics';
const ECONOMICS = 'Economics';
const EDUCATION = 'Education';
const HISTORY = 'History';
const SCIENCE = 'Science';
const PHILOSOPHY = 'Philosophy';
export function podcastTypesExtra(genreIconSize = 18.0) {
    return {
        '1': {
            'name': PSYCH,


            'hasFeaturedLists': true,
            'subGenres': [
                {
                    'id': '101',
                    'name': 'Make Better Decisions',
                    'possible': ['Books']
                },
            ]
        },
        '2': {
            'name': SELF_GROWTH,


            'hasFeaturedLists': true,
            'hasBlogLists': true,
            'subGenres': [
                {
                    'id': '201',
                    'name': 'Create Wealth',
                    'possible': ['Careers']
                },
            ]
        },
        '3': {
            'name': MINDFULNESS_HAPPINESS,


            'hasFeaturedLists': true,
            'hasBlogLists': true,
            'subGenres': [
                {
                    'id': '301',
                    'name': 'Comedy Interviews',
                    'possible': ['Comedy Interviews']
                },
            ]
        },
        '4': {
            'name': BUSINESS_CAREER,


            'hasFeaturedLists': true,
            'hasBlogLists': true,
            'subGenres': [
                {
                    'id': 'Courses',
                    'name': 'Courses',
                    'possible': ['Courses']
                },
            ]
        },
        '5': {
            'name': MANAGEMENT_LEADERSHIP,


            'hasFeaturedLists': true,
            'hasBlogLists': true,
            'subGenres': [
                {
                    'id': 'Comedy Fiction',
                    'name': 'Comedy Fiction',
                    'possible': ['Comedy Fiction']
                },
            ]
        },
        '6': {
            'name': ENTREPRENEURSHIP,


            'hasFeaturedLists': true,
            'hasBlogLists': true,
            'subGenres': [
                {
                    'id': 'Alternative Health',
                    'name': 'Alternative Health',
                    'possible': ['Alternative Health']
                },
            ]
        },
        '7': {
            'name': MARKETING_SALES,


            'hasFeaturedLists': true
        },
        '8': {
            'name': PRODUCTIVITY,


            'hasFeaturedLists': true,
            'subGenres': [
                {
                    'id': 'Education for Kids',
                    'name': 'Education for Kids',
                    'possible': ['Education for Kids']
                },
            ]
        },
        '9': {
            'name': MONEY_INVESTMENTS,


            'hasFeaturedLists': true,
            'subGenres': [
                {
                    'id': 'Animation & Manga',
                    'name': 'Animation & Manga',
                    'possible': ['Animation & Manga']
                },
            ]
        },
        '10': {
            'name': SEX_RELATIONSHIPS,


            'hasFeaturedLists': true,
            'subGenres': [
                {
                    'id': 'Music Commentary',
                    'name': 'Music Commentary',
                    'possible': ['Music Commentary']
                },
            ]
        },
        '11': {
            'name': HEALTH_NUTRITION,


            'hasFeaturedLists': true,
            'hasBlogLists': true,
            'subGenres': [
                {
                    'id': 'Business News',
                    'name': 'Business News',
                    'possible': ['Business News']
                },
            ]
        },
        '12': {
            'name': SPORTS_FITNESS,

        },
        '13': {
            'name': SOCIETY_CULTURE,


            'hasFeaturedLists': true,
            'subGenres': [
                {
                    'id': 'Astronomy',
                    'name': 'Astronomy',
                    'possible': ['Astronomy']
                },
            ]
        },
        '14': {
            'name': BIOGRAPHY_MEMOIR,


            'hasFeaturedLists': true,
            'hasBlogLists': true,
            'subGenres': [
                {
                    'id': 'Documentary',
                    'name': 'Documentary',
                    'possible': ['Documentary']
                },
            ]
        },
        '15': {
            'name': RELIGION_SPIRITUALITY,


            'hasFeaturedLists': true,
            'subGenres': [
                {
                    'id': 'Baseball',
                    'name': 'Baseball',
                    'possible': ['Baseball']
                }
            ]
        },
        '16': {
            'name': FAMILY_KIDS,


            'hasFeaturedLists': true,
            'hasBlogLists': true
        },
        '17': {
            'name': HOME_ENVIRONMENT,


            'hasFeaturedLists': true,
            'hasBlogLists': true
        },
        '18': {
            'name': MOTIVATION_INSPIRATION,


            'hasFeaturedLists': true,
            'hasBlogLists': true,
            'subGenres': [
                {
                    'id': 'After Shows',
                    'name': 'After Shows',
                    'possible': ['After Shows']
                },
            ]
        },
        '19': {
            'name': TECHNOLOGY_FUTURE,


            'hasFeaturedLists': true,
            'hasBlogLists': true
        },
        '20': {
            'name': CREATIVITY,


            'hasFeaturedLists': true,
            'hasBlogLists': true
        },
        '21': {
            'name': POLITICS,


            'hasFeaturedLists': true,
            'hasBlogLists': true
        },
        '22': {
            'name': ECONOMICS,


            'hasFeaturedLists': true,
            'hasBlogLists': true
        },
        '23': {
            'name': EDUCATION,


            'hasFeaturedLists': true,
            'hasBlogLists': true
        },
        '24': {
            'name': HISTORY,


            'hasFeaturedLists': true,
            'hasBlogLists': true
        },
        '25': {
            'name': SCIENCE,


            'hasFeaturedLists': true,
            'hasBlogLists': true
        },
        '26': {
            'name': PHILOSOPHY,


            'hasFeaturedLists': true,
            'hasBlogLists': true
        },
    };
}
export const podcastTypes = [
    {
        'id': '0',
        'name': 'Popular',
    },
    {
        'id': '1',
        'name': PSYCH,
    },
    {
        'id': '2',
        'name': SELF_GROWTH,
    },
    {
        'id': '3',
        'name': MINDFULNESS_HAPPINESS,
    },
    {
        'id': '4',
        'name': BUSINESS_CAREER,
    },
    {
        'id': '5',
        'name': MANAGEMENT_LEADERSHIP,
    },
    {
        'id': '6',
        'name': ENTREPRENEURSHIP,
    },
    {
        'id': '7',
        'name': MARKETING_SALES,
    },
    {
        'id': '8',
        'name': PRODUCTIVITY,
    },
    {
        'id': '9',
        'name': MONEY_INVESTMENTS,
    },
    {
        'id': '10',
        'name': SEX_RELATIONSHIPS,
    },
    {
        'id': '11',
        'name': HEALTH_NUTRITION,
    },
    {
        'id': '12',
        'name': SPORTS_FITNESS,
    },
    {
        'id': '13',
        'name': SOCIETY_CULTURE,
    },
    {
        'id': '14',
        'name': BIOGRAPHY_MEMOIR,
    },
    {
        'id': '15',
        'name': RELIGION_SPIRITUALITY,
    },
    {
        'id': '16',
        'name': FAMILY_KIDS,
    },
    {
        'id': '17',
        'name': HOME_ENVIRONMENT,
    },
    {
        'id': '18',
        'name': MOTIVATION_INSPIRATION,
    },
    {
        'id': '19',
        'name': TECHNOLOGY_FUTURE,
    },
    {
        'id': '20',
        'name': CREATIVITY,
    },
    {
        'id': '21',
        'name': POLITICS,
    },
    {
        'id': '22',
        'name': ECONOMICS,
    },
    {
        'id': '23',
        'name': EDUCATION,
    },
    {
        'id': '24',
        'name': HISTORY,
    },
    {
        'id': '25',
        'name': SCIENCE,
    },
    {
        'id': '26',
        'name': PHILOSOPHY,
    }
];

export const superGenres = (genreIconSize = 18.0) => {
    return [
        {
            'id': '10000',
            'name': 'Be More productive',
            'genres': ['8'],
            'icon': 'chart-line'
        },
        {
            'id': '20000',
            'name': 'Create Wealth',
            'genres': ['9'],
            'icon': 'dollar-sign'
        },
        {
            'id': '30000',
            'name': 'Make Better Decisions',
            'genres': ['1', '22'],
            'icon': 'brain'
        },
        {
            'id': '40000',
            'name': 'For Happier Life',
            'genres': ['3'],
            'icon': 'smile'
        },
        {
            'id': '50000',
            'name': 'For Better Career',
            'genres': ['4', '5', '6', '7'],
            'icon': 'briefcase'
        },
        {
            'id': '60000',
            'name': 'Improve your parenting',
            'genres': ['16'],
            'icon': 'baby-carriage'
        },
        {
            'id': '70000',
            'name': 'Get More Self-Confidence',
            'genres': ['2'],
            'icon': 'fist-raised'
        },
        {
            'id': '80000',
            'name': 'Better Relationships & Sex',
            'genres': ['10'],
            'icon': 'heart'
        },
        {
            'id': '90000',
            'name': 'Live a healthy life',
            'genres': ['11', '12'],
            'icon': 'apple-alt'
        },
        {
            'id': '100000',
            'name': 'For Self improvement',
            'genres': ['2', '13', '14', '26'],
            'icon': 'level-up-alt'
        },
        {
            'id': '110000',
            'name': 'To Acquire Knowledge',
            'genres': ['13', '14', '17', '19', '24', '25', '26'],
            'icon': 'book-open'
        },
        {
            'id': '120000',
            'name': 'Get Inspired',
            'genres': ['18'],
            'icon': 'lightbulb'
        },
    ];
}

export function bookImage(podcastData) {
    console.dir(podcastData);
    const imgName = podcastData.artworkUrl600
        ? podcastData.artworkUrl600
        : (`${podcastData?.collectionId ?? ''}`) + '.png';
    return imagesCDNUrl + 'book/' + imgName;
}

export const loadUser = (userDetails) => new Promise((resolve) => {
    if (userDetails) {
        resolve({ userDetails, newData: false });
    } else {
        firebase.auth().onAuthStateChanged(user => {
            // console.dir(user);
            if (user) {
                resolve({ userDetails: user, newData: true });
            } else {
                resolve(null);
            }
        })
    }
});

export function cleanText(text) {
    if (typeof text === 'undefined') {
        return '';
    }
    // const cleanedText = text.replace(/&amp;/g, '&').replace(/&apos;/g, "'").replace(/&quot;/g, "\"");
    // return cleanedText;
    return text; // he.decode(text);
}

export function doubleDigits(val) {
    return val < 10 ? '0' + val : val;
}

export function cleanFirebaseId(str) {
    if (str) {
        const v1 = str.replace(new RegExp('//', 'g'), '__');
        const v2 = v1.replace(new RegExp('/', 'g'), '_');
        const v3 = v2.replace(new RegExp('\\.', 'g'), '___');
        return v3;
    } else {
        return '';
    }
}

export function createPodcastObj(podcastInfo) {
    return {
        collectionName: podcastInfo.collectionName || podcastInfo.trackName,
        artistName: podcastInfo.artistName,
        artworkUrl600: podcastInfo.artworkUrl600,
        collectionId: podcastInfo.collectionId,
        feedUrl: podcastInfo.feedUrl,
    }
}

// export function createPodcastObj(podcastInfo) {
//     return {
//         collectionName: podcastInfo.collectionName || podcastInfo.trackName,
//         artistName: podcastInfo.artistName,
//         artworkUrl600: podcastInfo.artworkUrl600,
//         collectionId: podcastInfo.collectionId,
//         collectionExplicitness: podcastInfo.collectionExplicitness,
//         feedUrl: podcastInfo.feedUrl,
//         genres: podcastInfo.genres,
//         primaryGenreName: podcastInfo.primaryGenreName,
//         trackCount: podcastInfo.trackCount
//     }
// }

const episodesToFetchEachTime = 10;

export const episodesPerFetch = episodesToFetchEachTime;

export function getEpisodePaginationMap(totalItemCount) {
    const episodesPerFetch = episodesToFetchEachTime; // same as that used in cloud functions.
    // console.dir(episodesPerFetch);
    let lastFew;
    let paginationMap = new Map(); // {};
    if (totalItemCount <= episodesPerFetch) {
        lastFew = totalItemCount;
        paginationMap.set(`${lastFew} - 1`, { start: lastFew, count: 'all' });
    } else {
        lastFew = totalItemCount % episodesPerFetch;
        if (lastFew <= episodesPerFetch / 2) {
            lastFew = lastFew + episodesPerFetch;
        }
        // console.dir(totalItemCount - lastFew + 1);
        paginationMap.set(`${totalItemCount} - ${totalItemCount - lastFew + 1}`, { start: 'latest' });
        let lastEpisode = totalItemCount - lastFew + 1;
        while (lastEpisode > 1) {
            let firstEpisode = lastEpisode - 1;
            lastEpisode = firstEpisode - episodesPerFetch + 1;
            paginationMap.set(`${firstEpisode} - ${lastEpisode}`, { start: firstEpisode, count: episodesPerFetch });
        }
    }
    return paginationMap;
}

export function giveNumberToEpisodes(episodes, episodeFrom, totalItemCount) {
    if (episodes) {
        let episodeFirstNum = episodeFrom == 'latest' ? totalItemCount : episodeFrom;
        for (let episode of episodes) {
            episode['appEpisodeNumber'] = episodeFirstNum;
            episodeFirstNum--;
        }
    }
    return episodes;
}

export function getFirestoreIdFromSlug(userPlaylists, playlistSlugId) {
    console.log(userPlaylists);
    console.log(playlistSlugId);
    // const index = Object.values(userPlaylists).findIndex(item => item.slug === playlistSlugId);
    // const firestoreId = Object.keys(userPlaylists)[index];
    for (let key of Object.keys(userPlaylists)) {
        if (userPlaylists[key].slug === playlistSlugId) {
            return key;
        }
    }
}

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function createEpisodeObj(episodeInfo) {
    return {
        author: episodeInfo.author || '',
        enclosure: episodeInfo.enclosure || '',
        guid: episodeInfo.guid || '',
        pubDate: episodeInfo.pubDate || '',
        thumbnail: episodeInfo.thumbnail || '',
        artworkUrl600: episodeInfo.artworkUrl600 || '',
        description: episodeInfo.description || episodeInfo.content || '',
        title: episodeInfo.title || ''
    }
}

export function sortAlphabetically(a, b) {
    return a.collectionName.localeCompare(b.collectionName);
}

export function sortAlphabeticallyName(a, b) {
    return a.name.localeCompare(b.name);
}

export function sortPubDate(a, b) {
    if (b.pubDate && a.pubDate) {
        // return b.pubDate.localeCompare(a.pubDate);
        return b.pubDate - a.pubDate;
    } else {
        return false;
    }
}

export function sortNumericallyDesc(a, b) {
    return b.dateAdded['_seconds'] - a.dateAdded['_seconds'];
}

export function sortByTimeAsc(a, b) {
    return a['ordinalityDate'] - b['ordinalityDate'];
}
export function sortByTimeDesc(a, b) {
    return b['ordinalityDate'] - a['ordinalityDate'];
}

export function sortLastModifiedDate(a, b) {
    return b.lastModifiedDate['seconds'] - a.lastModifiedDate['seconds'];
}

export function sortNumericallyLastDesc(a, b) {
    return b.lastPlayed['seconds'] - a.lastPlayed['seconds'];
}

export function sortRating(a, b) {
    return b.rating - a.rating;
}

export const authError = {
    'auth/user-not-found': 'Incorrect username or password. Please try again.',
    'auth/email-already-in-use': 'You are already registered. Please click on Forgot Password to reset your password.',
    'auth/weak-password': 'Password should be at least 6 characters'
}