import React from 'react';
import PropTypes from 'prop-types';
import App from 'next/app';

import '../static/css/nprogress.css';

// import { initStore } from '../store';
import Head from '../components/Head';
import TopBar from '../components/TopBar/TopBar';
import Footer from '../components/BottomBar/Footer';
// import LeftBar from '../components/LeftBar/LeftBar';
// import CommonLayout from '../components/CommonLayout';
import { spacing, rightSideBar, maxScreenSize, minScreenSize, colors } from '../lib/theme';
import TopBarArticle from '../components/TopBar/TopBarArticle';

// import { loadUser } from '../lib/utils';
// import { getRequiredPodcasts } from '../reducers';
// import { setUserDetails, loadingUserDetailsState } from '../actions';
// import { fetchUserSubscriptions, getPersonalDetails, fetchSideBarPodcasts, fetchPlaylists } from '../actions';

// import NextSeo from 'next-seo';
import { NextSeo } from 'next-seo';

import NProgress from 'nprogress';
import Router from 'next/router';

// import sentry from '../utils/sentry';
// const { Sentry, captureException } = sentry({ release: process.env.SENTRY_RELEASE })
import * as gtag from '../lib/gtag';
import { isArticle, isOnboard, saveLocally, topBarPremiumHeight } from '../lib/utils';
import PremiumTop from '../components/Common/PremiumTop';
import TopBarMinimal from '../components/TopBar/TopBarMinimal';

Router.events.on('routeChangeStart', url => {
    // console.log(`Loading: ${url}`)
    NProgress.start()
})
Router.events.on('routeChangeComplete', (url) => {
    NProgress.done();
    gtag.pageview(url);
})
Router.events.on('routeChangeError', () => NProgress.done())

class Layout extends React.Component {
    componentDidMount() {
        try {
            const urlParams = new URLSearchParams(window.location.search);
            const isReferral = urlParams.get('fpr');
            if (isReferral) {
                saveLocally('isReferral', true, 30 * 24);
            }
        } catch (err) {
            console.error(err);
        }
        // console.log('test1');
        // if(window.innerWidth > 900) {
        //     setTimeout(() => hotjar.initialize(1327055, 6), 2000);
        // }
    }
    render() {
        const { children, currentRoute } = this.props;
        console.dir(currentRoute);
        // const { leftBarOpen, mainLayoutColor } = settingsProp;
        // const leftCloseClass = leftBarOpen ? '' : 'leftCloseClass';
        // const leftSideBarValue = leftBarOpen ? leftSideBar : '40px';
        const isArticlePage = isArticle(currentRoute);
        const isOnboardPage = isOnboard(currentRoute);
        const isPlayerGenPage = currentRoute === '/player-gen' || currentRoute === '/playlist-gen';
        return (
            <div className='layout-style'>
                <Head />
                {/* <LeftBar />
                <RightBar /> */}
                {/* <PremiumTop /> */}
                {currentRoute == '/goals' && <TopBarMinimal />}
                {!isPlayerGenPage && !isArticlePage && !isOnboardPage && <TopBar />}
                {!isPlayerGenPage && isArticlePage && !isOnboardPage && <TopBarArticle isArticlePage={isArticlePage} />}
                {/* {children} */}
                <div className="main">
                    {children}
                </div>
                {/* <BottomBar /> */}
                {!isPlayerGenPage && !isOnboardPage && <Footer />}
                <style jsx>
                    {`
            .layout-style {
                // margin-right: ${rightSideBar};
            }
            .main {
                // padding: ${spacing('xl')};
                padding-bottom: 5%;
                // padding-top: ${spacing('lg')};
                // margin-top: calc(${spacing('lg')} + ${spacing('xl')});
                margin-top: ${!isPlayerGenPage && isArticlePage ? '20px' : topBarPremiumHeight}; // same as top bar height
                height: auto; // 100vh;
                min-height: 100vh;
                background: #fefdf7;
            }
            :global(a){
                // color: ${colors.primary};
            }
            @media ${maxScreenSize.tablet} {
                .layout-style{
                    margin-right: 0;
                    // margin-left: 40px;
                }
                .main {
                    // padding: ${spacing('xs')};
                    padding-bottom: 25%;
                }
            }
            @media ${minScreenSize.tablet} and ${maxScreenSize.laptop} {
                .layout-style{
                    margin-right: 0;
                    // margin-left: 40px;
                }
                .main {
                    // padding: ${spacing('lg')};
                    padding-bottom: 10%;
                }
            }
          `}
                </style>
            </div>
        )
    }
}

Layout.propTypes = {
    children: PropTypes.element,
    currentRoute: PropTypes.any,
    // settingsProp: PropTypes.object
}

class MyApp extends App {
    // static async getInitialProps({ Component, ctx }) {
    //     try {
    //         let pageProps = {}

    //         if (Component.getInitialProps) {
    //             pageProps = await Component.getInitialProps(ctx)
    //         }

    //         return { pageProps }
    //     } catch (error) {
    //         // Capture errors that happen during a page's getInitialProps.
    //         // This will work on both client and server sides.
    //         const errorEventId = captureException(error, ctx)
    //         return {
    //             hasError: true,
    //             errorEventId
    //         }
    //     }
    // }

    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            errorEventId: undefined
        }
    }

    componentDidMount() {
        // //get top podcasts or sponsored podcasts in future.
        // this.props.fetchSideBarPodcasts();
    }

    // static getDerivedStateFromProps(props, state) {
    //     // If there was an error generated within getInitialProps, and we haven't
    //     // yet seen an error, we add it to this.state here
    //     return {
    //         hasError: props.hasError || state.hasError || false,
    //         errorEventId: props.errorEventId || state.errorEventId || undefined
    //     }
    // }

    // static getDerivedStateFromError() {
    //     // React Error Boundary here allows us to set state flagging the error (and
    //     // later render a fallback UI).
    //     return { hasError: true }
    // }

    // componentDidCatch(error, errorInfo) {
    //     const errorEventId = captureException(error, { errorInfo });
    //     // Store the event id at this point as we don't have access to it within
    //     // `getDerivedStateFromError`.
    //     this.setState({ errorEventId })
    // }

    render() {
        const { Component, pageProps, router } = this.props
        return this.state.hasError ? (
            <section style={{ textAlign: 'center', marginTop: '10%' }}>
                <div className='no-page-found'></div>
                <h3>There was an unexpected error and our page went missing&#33;&#33;&#33;</h3>
                {/* <p>
                    <a href='#'
                        onClick={() => Sentry.showReportDialog({ eventId: this.state.errorEventId })}>
                        📣 Please help us find it by <span style={{ textDecoration: 'underline' }}>reporting</span> this error
                </a>
                </p> */}
                <p>
                    <a href='#'
                        onClick={() => { window.location.reload(true) }}>
                        Or, try <span style={{ textDecoration: 'underline' }}>reloading</span> the page
                    </a>
                </p>
                <style jsx>
                    {`
                    .no-page-found {
                        text-align: center;
                        height: 50vh;
                        background: url(/static/images/empty-state/page-not-found.svg);
                        background-size: 120% 98%;
                        background-position: center top;
                        background-repeat: no-repeat;
                    }
                `}</style>
            </section>
        ) : (
            // Render the normal Next.js page
            // <Container>
            <>
                <NextSeo
                    title='Accelerated: Book Summaries App'
                    description='Best place for Podcasts'
                    canonical='https://tedapp.ai/'
                    openGraph={{
                        type: 'website',
                        locale: 'en_IE',
                        url: 'https://tedapp.ai/',
                        site_name: 'Accelerated',
                    }}
                    facebook={{
                        appId: 331590264386487,
                    }}
                    twitter={{
                        handle: '@tedapp',
                        site: '@tedapp',
                        cardType: 'summary',
                    }}
                />
                {/* <Provider store={store}> */}
                <Layout currentRoute={router.route}>
                    <Component {...pageProps} />
                </Layout>
                {/* </Provider> */}
            </>
            // </Container>
        )
    }
}

// const mapStateToProps = (state) => {
//     return {
//         // userDetails: state.userDetails,
//         // subscribedPodcasts: state.podcastState,
//         // userPlaylists: state.userPlaylists,
//         settingsState: state.settingsState,
//         // userPlaylists: state.userPlaylists,
//     }
// }

// const mapDispatchToProps = (dispatch) => {
//     return {
//         // setUserDetails: bindActionCreators(setUserDetails, dispatch),
//         // loadingUserDetailsState: bindActionCreators(loadingUserDetailsState, dispatch),
//         // fetchUserSubscriptions: bindActionCreators(fetchUserSubscriptions, dispatch),
//         // getPersonalDetails: bindActionCreators(getPersonalDetails, dispatch),
//         // fetchSideBarPodcasts: bindActionCreators(fetchSideBarPodcasts, dispatch),
//         // fetchPlaylists: bindActionCreators(fetchPlaylists, dispatch)
//     }
// };

export default MyApp;
// export default compose(
//     withRedux(initStore, {
//         ssr: false
//     }),
//     connect(mapStateToProps, mapDispatchToProps)
// )(MyApp);